/* eslint-disable wp-lint-rules/valid-href */
import React from "react";
import { LottieAnimation } from "../../shared-components";
import { useTrans } from "../../hooks";

const ValidationSuccess = () => {
  const { trans } = useTrans();

  return (
    <div className="validation-success">
      <div className="message-wrapper">
        <div className="animation-wrapper">
          <LottieAnimation
            animationId="validation-success-animation"
            animationOptions={{
              animType: "svg",
              loop: false,
              path: "/img/lottie-animations/success.json"
            }}
          />
        </div>
        <h1>Success!</h1>
        <p>{trans("Your email is verified and sign-up is complete. Enjoy!")}</p>
        <a className="btn-primary" href="/home">
          {trans("Continue")}
        </a>
      </div>
    </div>
  );
};

export default ValidationSuccess;
