/* eslint-disable wp-lint-rules/valid-href */
import React from "react";
import { useTrans } from "../../hooks";
import { ConnectImage } from "../../shared-components";

const StoryError404 = () => {
  const { trans } = useTrans();

  return (
    <div id="story-404-wrapper">
      <ConnectImage name={"errors/story-404.svg"} className="error-404-image" />
      <h3>
        {trans("This page got lost in a good story and never came back.")}
      </h3>
      <p>
        {trans(
          "We couldn’t find what you’re looking for. This page might have moved, no longer exists, or is only visible to a selected audience."
        )}
      </p>
      <div className="buttons-404">
        <a className="btn-primary" href="./home">
          {trans("Go home")}
        </a>
      </div>
    </div>
  );
};

export default StoryError404;
