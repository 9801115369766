import React from "react";

import StorySwimlaneWithHeroItem from "./sections/StorySwimlaneWithHeroItem";
import ContinueReadingSwimlane from "./sections/ContinueReading/ContinueReadingSwimlane.js";
import StorySwimlane from "./sections/StorySwimlane";
import FeaturedCarousel from "./sections/FeaturedCarousel";
import SmallNavigation from "./sections/SmallNavigation";
import StorySpotlight from "./sections/StorySpotlight";
import CTA from "./sections/CTA";
import PropTypes from "prop-types";
import rtlLanguage from "../../../helpers/handlebars/rtl-language";

import { HeadingPillType } from "./components/SectionHeader.js";

const SectionFactory = props => {
  const defaults = {
    sectionType: props.type,
    index: props.index,
    setPosition: props.setPosition,
    windowWidth: props.windowWidth,
    wasSeen: props.wasSeen,
    page: "home"
  };

  switch (props.type) {
    case "paidMultiRow":
    case "paid":
    case "storyHeroGroup":
      return (
        <StorySwimlaneWithHeroItem
          {...defaults}
          expandPrompt={props.data.expandPrompt}
          heading={props.data.heading}
          subheading={props.data.subheading}
          heroItem={props.data.heroItem || props.data.heroItems[0]}
          storyItems={props.data.listItems}
          multiRows={props.data.rows}
          navigationItem={props.data.navigation}
          isPromoted={props.data.isPromoted}
          clickTrackingUrls={props.data.tracking && props.data.tracking.clicks}
          impressionTrackingUrls={
            props.data.tracking && props.data.tracking.impressions
          }
          callTrackingUrls={props.callTrackingUrls}
          onStoryClick={props.openStoryDetails}
        />
      );
    case "storyExpanded":
    case "storyList":
      return (
        <StorySwimlane
          {...defaults}
          expandPrompt={props.data.expandPrompt}
          heading={props.data.heading}
          background={props.data.background}
          subheading={props.data.subheading}
          stories={props.data.items}
          isPromoted={props.data.isPromoted}
          clickTrackingUrls={props.data.tracking && props.data.tracking.clicks}
          callTrackingUrls={props.callTrackingUrls}
          onStoryClick={props.openStoryDetails}
        />
      );
    case "rankedStoryList":
      return (
        <StorySwimlane
          {...defaults}
          heading={props.data.heading}
          subheading={props.data.subheading}
          stories={props.data.items}
          storyWidth={300}
          mobileStoriesToShow={2.8}
          clickTrackingUrls={props.data.tracking && props.data.tracking.clicks}
          callTrackingUrls={props.callTrackingUrls}
        />
      );
    case "continueReadingSwimlane":
      return (
        <ContinueReadingSwimlane
          {...defaults}
          heading={props.data.heading}
          subheading={props.data.subheading}
          url={props.dynamic.path}
          data={props.data.items}
          isMobile={wattpad.utils.getDeviceType() === "mobile"}
          isRTL={rtlLanguage(parseInt(app.get("language"), 10))}
        />
      );
    case "premiumPicksRow":
      return (
        <StorySwimlane
          {...defaults}
          heading={props.data.heading}
          headingPill={{
            type: HeadingPillType.COUNTDOWN,
            date: props.data.expiresAt
          }}
          subheading={props.data.subheading}
          expandPrompt={{
            ...props.data.expandPrompt,
            tracking: {
              page: "premium_picks",
              source: "home"
            }
          }}
          stories={props.data.items}
        />
      );

    case "featured":
      return (
        !!props.data.items && (
          <FeaturedCarousel
            {...defaults}
            slides={props.data.items}
            wrapAround={true}
          />
        )
      );
    case "cta":
      return (
        <CTA
          {...defaults}
          prompt={props.data.prompt}
          button={props.data.button}
          message={props.data.message}
          background={props.data.background}
          weblink={props.data.weblink}
          image={props.data.image}
        />
      );
    case "smallNavigation":
      return (
        <SmallNavigation
          {...defaults}
          heading={props.data.heading}
          subheading={props.data.subheading}
          background={props.data.background}
          link={props.data.weblink}
          image={props.data.image}
        />
      );
    case "greeting":
      return null;
    case "storySpotlight":
      return (
        <StorySpotlight
          {...props.data.item}
          {...defaults}
          onLibraryClick={props.onLibraryClick}
          readingLists={props.readingLists}
          getCollectionsForStoryIds={props.getCollectionsForStoryIds}
          storiesCollections={props.storiesCollections}
          storiesCollectionsLoading={props.storiesCollectionsLoading}
        />
      );
    default:
      return null;
  }
};

SectionFactory.propTypes = {
  index: PropTypes.number.isRequired,
  setPosition: PropTypes.func.isRequired,
  windowWidth: PropTypes.number,
  wasSeen: PropTypes.bool.isRequired,
  type: PropTypes.string,
  dynamic: PropTypes.object,
  openContentSettings: PropTypes.func,
  openStoryDetails: PropTypes.func,
  onLibraryClick: PropTypes.func,
  readingLists: PropTypes.array,
  getCollectionsForStoryIds: PropTypes.func,
  storiesCollections: PropTypes.object,
  storiesCollectionsLoading: PropTypes.bool,
  data: PropTypes.shape({
    expandPrompt: PropTypes.object,
    heading: PropTypes.string,
    background: PropTypes.string,
    subheading: PropTypes.string,
    isPromoted: PropTypes.bool,
    heroItem: PropTypes.object,
    heroItems: PropTypes.array,
    navigation: PropTypes.object,
    clickTrackingUrls: PropTypes.array,
    listItems: PropTypes.array,
    greeting: PropTypes.string,
    image: PropTypes.string,
    prompt: PropTypes.string,
    button: PropTypes.string,
    message: PropTypes.string,
    weblink: PropTypes.string,
    items: PropTypes.array,
    item: PropTypes.object,
    tracking: PropTypes.object,
    rows: PropTypes.array,
    showContentSettings: PropTypes.bool,
    expiresAt: PropTypes.string
  }),
  callTrackingUrls: PropTypes.func
};

export default SectionFactory;
